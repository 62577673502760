import {
  Button, Drawer, Snackbar, Typography, Tooltip,
  Checkbox, FormControlLabel, List,
  ListItem, ListItemText
} from "@mui/material";
import { OpenInNew, ExpandLess, ExpandMore, Warning } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnDef } from "../../types.d";
import AlertMessage from "../body/common/AlertMessage";
import { InputWrapper } from "../body/common/InputWrapper";
import { CustomInputField } from "../body/new-inputs/InputField";
import { RestartButton } from "../body/common/ControlRow";
import moment from "moment-timezone"
import {
  FixColumnDef,
  accountNameDef,
  clearingGroupDef,
  routeDef,
  symbolDef,
  startTimeDef,
  endTimeDef,
  buyingPowerGroupDef,
} from "../hooks/columndefs";
import { useArgs } from "../hooks/useQuery";
import { AccountField, getAccountFieldCSV } from "../../utils/accountField";
import CustomAccordion from "./CustomAccordion";

const startDateDef = FixColumnDef({
  rdgColumn: {
    key: "startDate",
    name: "Start Date",
  },
  type: "date",
});
const endDateDef = FixColumnDef({
  rdgColumn: {
    key: "endDate",
    name: "End Date",
  },
  type: "date",
});
const dateDef = FixColumnDef({
  rdgColumn: {
    key: "date",
    name: "Date",
  },
  type: "date",
});
const datesDef = FixColumnDef({
  rdgColumn: {
    key: "dates",
    name: "Dates (yyyyMMdd)",
  },
  type: "text",
});
const emailToDef = FixColumnDef({
  rdgColumn: {
    key: "emailTo",
    name: "Emails",
  },
  type: "text",
});
const executionStartTimeDef = FixColumnDef({
  rdgColumn: {
    key: "executionStartTime",
    name: "Execution start time",
  },
  type: "time",
});
const executionEndTimeDef = FixColumnDef({
  rdgColumn: {
    key: "executionEndTime",
    name: "Execution end time",
  },
  type: "time",
});
const accountIdsDef = FixColumnDef({
  rdgColumn: {
    key: "accountIds",
    name: "Account Ids"
  },
  type: "number",
});
const modifiedAccountNamesDef = {
  ...accountNameDef,
  rdgColumn: {
    ...accountNameDef.rdgColumn,
    key: "accountNames",
    name: "Account Names"
  }
};
const accountTypesDef = FixColumnDef({
  rdgColumn: {
    key: "accountTypes",
    name: "Account Types"
  },
  type: "text",
});
const subAccountNamesDef = FixColumnDef({
  rdgColumn: {
    key: "subAccountNames",
    name: "Sub Account Names"
  },
  type: "text",
});
const modifiedBuyingPowerGroupsDef = {
  ...buyingPowerGroupDef,
  rdgColumn: {
    ...buyingPowerGroupDef.rdgColumn,
    key: "buyingPowerGroups",
    name: "Buying Power Groups"
  }
};
const clearingFirmsDef = FixColumnDef({
  rdgColumn: {
    key: "clearingFirms",
    name: "Clearing Firms"
  },
  type: "text"
})
const modifiedClearingGroupNamesDef = {
  ...clearingGroupDef,
  rdgColumn: {
    ...clearingGroupDef.rdgColumn,
    key: "clearingGroupNames",
    name: "Clearing Group Names"
  }
};
const clearingIdsDef = FixColumnDef({
  rdgColumn: {
    key: "clearingIds",
    name: "Clearing Ids"
  },
  type: "text"
})
const clusterNamesDef = FixColumnDef({
  rdgColumn: {
    key: "clusterNames",
    name: "Cluster Names"
  },
  type: "text",
});
const mpidsDef = FixColumnDef({
  rdgColumn: {
    key: "mpids",
    name: "Mpids",
  },
  type: "text",
});
const tradingDomainNamesDef = FixColumnDef({
  rdgColumn: {
    key: "tradingDomainNames",
    name: "Trading Domain Names",
  },
  type: "text",
});
const modifiedVenuesDef = {
  ...routeDef,
  rdgColumn: {
    ...routeDef.rdgColumn,
    key: "venues",
    name: "Venues"
  }
};
const routesDef = FixColumnDef({
  rdgColumn: {
    key: "routes",
    name: "Routes",
  },
  type: "text",
});
const modifiedSymbolsDef = {
  ...symbolDef,
  rdgColumn: {
    ...symbolDef.rdgColumn,
    key: "symbols",
    name: "Symbols"
  }
};
const allowRoutingDef = FixColumnDef({
  rdgColumn: {
    key: "allowRoutingOrderOption",
    name: "Allow routing",
  },
  type: "boolean",
});
const imbalanceOnlyDef = FixColumnDef({
  rdgColumn: {
    key: "imbalanceOnlyOrderOption",
    name: "Imbalance only",
  },
  type: "boolean",
});
const invisibleDef = FixColumnDef({
  rdgColumn: {
    key: "invisibleOrderOption",
    name: "Invisible",
  },
  type: "boolean",
});
const pegTypeDef = FixColumnDef({
  rdgColumn: {
    key: "pegTypeOrderOption",
    name: "Peg type",
  },
  type: "text",
});
const shortSaleAffirmDef = FixColumnDef({
  rdgColumn: {
    key: "shortSaleAffirmOrderOption",
    name: "Short sale affirm",
  },
  type: "boolean",
});
const isoOnlyDef = FixColumnDef({
  rdgColumn: {
    key: "isoOnly",
    name: "ISO Only",
  },
  type: "boolean",
});
const filledOnlyDef = FixColumnDef({
  rdgColumn: {
    key: "filledOnly",
    name: "Filled Only",
  },
  type: "boolean",
});
const unfilledOnlyDef = FixColumnDef({
  rdgColumn: {
    key: "unfilledOnly",
    name: "Unfilled Only",
  },
  type: "boolean",
});
const preventDuplicateOrdersDef = FixColumnDef({
  rdgColumn: {
    key: "preventDuplicateOrders",
    name: "Prevent Duplicates Orders",
  },
  type: "boolean",
});
const dedicatedClusterDef = FixColumnDef({
  rdgColumn: {
    key: "dedicatedCluster",
    name: "Dedicated Cluster",
  },
  type: "boolean",
});
const equitiesFileNameDef = FixColumnDef({
  rdgColumn: {
    key: "equitiesFileName",
    name: "Equities file name",
  },
  type: "text",
});
const optionsFileNameDef = FixColumnDef({
  rdgColumn: {
    key: "optionsFileName",
    name: "Options file name",
  },
  type: "text",
});
const rowCountPerFileDef = FixColumnDef({
  rdgColumn: {
    key: "rowCountPerFile",
    name: "Row count per file",
  },
  type: "number",
});
const outputBzip2CompressedDef = FixColumnDef({
  rdgColumn: {
    key: "outputBzip2Compressed",
    name: "Compress the output files",
  },
  type: "boolean",
});
const outputDirectoryNameDef = FixColumnDef({
  rdgColumn: {
    key: "outputDirectoryName",
    name: "Output Directory Name",
  },
  type: "text",
});
const useOrderOptionsInOutputDef = FixColumnDef({
  rdgColumn: {
    key: "useOrderOptionsInQuery",
    name: "Use Order Options in Output",
  },
  type: "boolean",
});

const dateDefs: Array<Required<ColumnDef>> = [
  startDateDef,
  endDateDef,
  datesDef,
  dateDef
]
const timeRefs: Array<Required<ColumnDef>> = [
  startTimeDef, //TODO all defs here need microsecond precision
  endTimeDef,
  executionStartTimeDef,
  executionEndTimeDef,
]
const accountRefs: Array<Required<ColumnDef>> = [
  accountIdsDef,
  modifiedAccountNamesDef,
  accountTypesDef,
  modifiedBuyingPowerGroupsDef,
  clearingFirmsDef,
  modifiedClearingGroupNamesDef,
  clearingIdsDef,
  subAccountNamesDef,
  clusterNamesDef,
  mpidsDef,
  tradingDomainNamesDef,
]
const basicDefs: Array<Required<ColumnDef>> = [
  emailToDef,
  modifiedVenuesDef,
  modifiedSymbolsDef,
]

const advancedDefs: Array<Required<ColumnDef>> = [
  //DATA TYPE FIELDS
  //TODO equitiesIncluded if default value is available
  //TODO optionsIncluded if default value is available
  //SPECIFIC ROUTE
  routesDef,
  //ORDER OPTIONS FIELDS (not important, try to tuck away)
  allowRoutingDef,
  imbalanceOnlyDef,
  invisibleDef,
  pegTypeDef,
  shortSaleAffirmDef,
  useOrderOptionsInOutputDef,
  //EXTRA SEARCH OPTIONS
  isoOnlyDef,
  filledOnlyDef,
  unfilledOnlyDef,
  preventDuplicateOrdersDef,
  //FILE MANIPULATION
  equitiesFileNameDef,
  optionsFileNameDef,
  rowCountPerFileDef,
  outputBzip2CompressedDef,
  outputDirectoryNameDef,
  //CLUSTER CREATION SETTINGS
  dedicatedClusterDef
];

async function getResults(limit?: number) {
  const limitQuery = limit ? `?limit=${limit}` : '';
  const resp = await fetch(`/api/complianceQuery${limitQuery}`);
  if (resp.ok) {
    return await resp.json();
  }
}

const isValidStringOrArray = (value) => {
  if (typeof value === 'string') {
    return value.trim().length > 0;
  } else if (Array.isArray(value)) {
    return value.length > 0 && value.every(item => typeof item === 'string' && item.trim().length > 0);
  }
  return false;
};

export const formatDate = (date: string): string => {
  return moment(date).format('YYYYMMDD');
};

export const formatTime = (date: string): string => {
  return moment(date).tz("America/New_York").format('HH:mm:ss.SSS');
};

let debounceTimer: NodeJS.Timeout | null = null;

function Submit(props: {
  onResults: (x: any) => void,
  isValidEmail: boolean,
  isValidDate: boolean,
  isValidAccountFields: boolean,
  accountFieldCheckboxes: Set<AccountField>,
  autoAccountHeaderInclusion: boolean
}) {
  const args = useArgs();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const isFormValid = props.isValidEmail && props.isValidDate && props.isValidAccountFields;

  const submitReport = useCallback(() => {
    if (isFormValid) {
      (async () => {
        const formattedArgs = {
          ...args,
          accountHeaderIncludedFields: getAccountFieldCSV(props.accountFieldCheckboxes),
          autoAccountHeaderInclusion: props.autoAccountHeaderInclusion,
          startDate: typeof args.startDate === 'string' ? formatDate(args.startDate) : args.startDate,
          endDate: typeof args.endDate === 'string' ? formatDate(args.endDate) : args.endDate,
          date: typeof args.date === 'string' ? formatDate(args.date) : args.date,
          startTime: typeof args.startTime === 'string' ? formatTime(args.startTime) : args.startTime,
          endTime: typeof args.endTime === 'string' ? formatTime(args.endTime) : args.endTime,
          executionStartTime: typeof args.executionStartTime === 'string' ? formatTime(args.executionStartTime) : args.executionStartTime,
          executionEndTime: typeof args.executionEndTime === 'string' ? formatTime(args.executionEndTime) : args.executionEndTime,
        };
        if (debounceTimer) {
          clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(async () => {
          const resp = await fetch("/api/complianceQuery", {
            body: JSON.stringify(formattedArgs),
            method: "POST",
          });
          console.log("JENKINS", { resp });
        }, 300) // 300 milliseconds debounce time
        props.onResults(await getResults(10));

        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
        }, 3000);
      })();
    }
  }, [args, isFormValid, props.accountFieldCheckboxes, props.autoAccountHeaderInclusion , setShowSnackbar]);

  const handleClose = useCallback(() => {
    setShowSnackbar(false)
  }, [setShowSnackbar])

  return (
    <>
      <Tooltip title={isFormValid ? "Click to submit" : "Please fill in all required fields"}>
        <span>
          <Button
            variant="contained"
            sx={{ width: '200px', height: '50px', backgroundColor: isFormValid ? 'green' : 'grey' }}
            onClick={submitReport}
            disabled={!isFormValid}
          >
            {isFormValid ? "Create Report" : "Fill Required Fields"}
          </Button>
        </span>
      </Tooltip>
      {
        <Snackbar
          open={showSnackbar}
          onClose={handleClose}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div>
            <AlertMessage title="Success" severity="success" onClose={handleClose}>Job submitted successfully</AlertMessage>
          </div>
        </Snackbar>
      }
    </>
  );
}

const PARAMETER_KEYS = [
  "START_DATE",
  "END_DATE",
  "DATE",
  "DATES",
  "EMAIL_TO",
  "ALLOW_ROUTING_ORDER_OPTION",
  "ACCOUNT_IDS",
  "ACCOUNT_NAMES",
  "ACCOUNT_TYPES",
  "BUYING_POWER_GROUPS",
  "CLEARING_FIRMS",
  "CLEARING_GROUP_NAMES",
  "CLEARING_IDS",
  "CLUSTER_NAMES",
  "DEDICATED_CLUSTER",
  "EXECUTION_START_TIME",
  "EXECUTION_END_TIME",
  "START_TIME",
  "END_TIME",
  "FILLED_ONLY",
  "IMBALANCE_ONLY_ORDER_OPTION",
  "INVISIBLE_ORDER_OPTION",
  "ISO_ONLY",
  "MPIDS",
  "PEG_TYPE_ORDER_OPTION",
  "ROUTES",
  "SHORT_SALE_AFFIRM_ORDER_OPTION",
  "SUB_ACCOUNT_NAMES",
  "SYMBOLS",
  "TRADING_DOMAIN_NAMES",
  "UNFILLED_ONLY",
  "USE_ORDER_OPTIONS_IN_QUERY",
  "OUTPUT_BZIP2_COMPRESSED",
  "VENUES",
  "EQUITIES_FILE_NAME",
  "OPTIONS_FILE_NAME"
] as const;

function Job({ job }: { job: any }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const parameters = useMemo(() => {
    const actionsParamIndex = job?.actions?.findIndex(
      (x: { [key: string]: any; }) => x["_class"] === "hudson.model.ParametersAction"
    );
    const params = job["actions"][actionsParamIndex]["parameters"];

    const nonEmptyKeys = PARAMETER_KEYS.filter((key) => {
      const index = params.findIndex((x: { [x: string]: string; }) => x["name"] === key);
      return index !== -1 && params[index]["value"];
    });

    const nonEmptyParams = nonEmptyKeys.map(
      (key) => params[params.findIndex((x: { [x: string]: string; }) => x["name"] === key)]["value"]
    );
    return { keys: nonEmptyKeys, values: nonEmptyParams };
  }, [job]);

  const getBackgroundColor = (result: string): string => {
    return result === 'SUCCESS' ? 'green' :
      result === 'FAILURE' ? 'red' : 'grey';
  }

  const bgColor: string = getBackgroundColor(job?.result);

  return (
    <Box
      sx={{
        padding: 1,
        borderRadius: "10px",
        backgroundColor: bgColor,
        marginBottom: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">
            {job["fullDisplayName"].match(/#\d+/)}
          </Typography>
          <a href={job["url"]} target="_blank" rel="noreferrer" style={{ marginLeft: '8px' }}>
            <OpenInNew fontSize="small" />
          </a>
        </Box>
        <Box onClick={toggleExpanded}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>
      <Typography>
        Started: {new Date(job["timestamp"]).toLocaleString()}
      </Typography>
      {expanded && (
        <Box
          sx={{
            height: "100%",
            overflowX: "auto",
            overflowY: "auto",
          }}
        >
          <List sx={{ padding: 0 }}>
            {parameters.values.map((p, i) => (
              <ListItem key={i} sx={{ padding: '4px 0' }}>
                <ListItemText
                  primary={parameters.keys[i]}
                  secondary={p}
                  sx={{ margin: 0 }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
}

function ComplianceQueryToolRestartButton({ onAdditionalReset }) {
  const handleAdditionalReset = () => {
    onAdditionalReset();
  };

  return (
    <div onClick={handleAdditionalReset}>
      {/* Render the original RestartButton which maintains its own reset functionality */}
      <RestartButton />
    </div>
  );
}

const ComplianceQueryTool = () => {
  const [jobs, setJobs] = useState<any[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [autoAccountHeaderInclusion, setAutoAccountHeaderInclusion] = useState(false);
  const [accountFieldCheckboxes, setAccountFieldCheckboxes] = useState<Set<AccountField>>(new Set());
  const [accountFieldPreview, setAccountFieldPreview] = useState('');

  const handleAdditionalReset = () => {
    setAutoAccountHeaderInclusion(false);
    setAccountFieldCheckboxes(new Set<AccountField>());
    setAccountFieldPreview('');
  };

  const args = useArgs();
  const isValidEmail = isValidStringOrArray(args.emailTo);
  const isValidDate = Boolean((args.startDate && args.endDate) || isValidStringOrArray(args.dates) || args.date);
  const isValidAccountFields =
    (accountFieldCheckboxes.size === 0) ||
    (accountFieldCheckboxes.has(AccountField.ACCOUNT) || accountFieldCheckboxes.has(AccountField.ID)) ||
    (autoAccountHeaderInclusion && (
      isValidStringOrArray(args.accountIds) || isValidStringOrArray(args.accountNames)
    ));

  const toggleAccountField = (field: AccountField) => {
    setAccountFieldCheckboxes(prevSelectedFields => {
      const newSelectedFields = new Set(prevSelectedFields);
      if (newSelectedFields.has(field)) {
        newSelectedFields.delete(field);
      } else {
        newSelectedFields.add(field);
      }
      return newSelectedFields;
    });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const generateAccountFieldPreview = () => {
    let accountFieldPreviewSet = new Set<AccountField>(
      accountFieldCheckboxes.size === 0 ? [AccountField.ACCOUNT] : accountFieldCheckboxes
    );
    if (autoAccountHeaderInclusion) {
      if (isValidStringOrArray(args.accountIds)) accountFieldPreviewSet.add(AccountField.ID);
      if (isValidStringOrArray(args.accountNames)) accountFieldPreviewSet.add(AccountField.ACCOUNT)
      if (isValidStringOrArray(args.accountTypes)) accountFieldPreviewSet.add(AccountField.TYPE);
      if (isValidStringOrArray(args.buyingPowerGroups)) accountFieldPreviewSet.add(AccountField.BUYING_POWER_GROUP);
      if (isValidStringOrArray(args.clearingFirms)) accountFieldPreviewSet.add(AccountField.CLEARING_FIRM);
      if (isValidStringOrArray(args.clearingIds)) accountFieldPreviewSet.add(AccountField.CLEARING_ID);
      if (isValidStringOrArray(args.clearingGroupNames)) accountFieldPreviewSet.add(AccountField.CLEARING_GROUP);
      if (isValidStringOrArray(args.clusterNames)) accountFieldPreviewSet.add(AccountField.CLUSTER);
      if (isValidStringOrArray(args.mpids)) accountFieldPreviewSet.add(AccountField.MPID);
      if (isValidStringOrArray(args.tradingDomainNames)) accountFieldPreviewSet.add(AccountField.TRADING_DOMAIN);
    }
    const accountFieldPreviewCSV = getAccountFieldCSV(accountFieldPreviewSet);
    setAccountFieldPreview(accountFieldPreviewCSV);
  };

  const getHighlightKeys = () => {
    if (args.startDate && args.endDate) {
      return ['startDate', 'endDate'];
    } else if (isValidStringOrArray(args.dates)) {
      return ['dates'];
    } else if (args.date) {
      return ['date'];
    }
    return []; // No keys to highlight
  };

  useEffect(() => {
    (async () => {
      setJobs(await getResults(10));
    })();
  }, []);

  return (
    <Box>
      {/* Top Bar */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 2,
          borderBottom: '1px solid grey',
        }}
      >
        {/* Left-aligned Buttons */}
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button
            sx={{ width: '200px', height: '50px' }}
            onClick={toggleDrawer(true)}
          >
            View Latest Builds
          </Button>

          {/* Add some spacing between the buttons */}
          <Box sx={{ width: '20px' }}></Box>

          <Button
            sx={{ width: '200px', height: '50px' }}
            onClick={() => window.open("https://s3.console.aws.amazon.com/s3/buckets/limeoutput?region=us-east-1&prefix=emr/cqt/", "_blank")}
          >
            Access Output Files
          </Button>
        </Box>

        {/* Right-aligned Submit and Restart buttons */}
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Submit
            onResults={setJobs}
            isValidEmail={isValidEmail}
            isValidDate={isValidDate}
            isValidAccountFields={isValidAccountFields}
            accountFieldCheckboxes={accountFieldCheckboxes}
            autoAccountHeaderInclusion={autoAccountHeaderInclusion}
          />
          <Box sx={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {/* Use ComplianceQueryToolRestartButton with the handleAdditionalReset function */}
          <ComplianceQueryToolRestartButton onAdditionalReset={handleAdditionalReset} />
        </Box>
        </Box>
      </Box>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 300,
            padding: 2,
            position: 'relative'  // Make this Box relative to position the button absolutely
          }}
        >
          {/* Header with Refresh Button */}
          <Box
            sx={{
              display: 'flex',  // Use flexbox
              justifyContent: 'space-between',  // Space items between start and end
              alignItems: 'center',  // Center items vertically
            }}
          >
            <Typography variant="h5">Latest Builds</Typography>
            <Button onClick={async () => {
              const newResults = await getResults(10);  // Fetch latest 10 Jenkins builds
              setJobs(newResults);  // Update the state with the new results
            }}>
              Refresh
            </Button>
          </Box>

          {jobs && jobs.map((job, i) => (
            <Job key={i} job={job} />
          ))}
        </Box>
      </Drawer>

      {/* Form for Submission */}
      <Box display="flex" flexDirection="column" alignItems="center">

        {/* Basic Fields */}
        <Box width="100%">
          {basicDefs.map((def, i) => (
            <Box key={i} sx={{ display: 'flex', alignItems: 'center', paddingLeft: 1 }}>
              <InputWrapper def={def}>
                <CustomInputField columnDefinition={def} noDecimals hideMode />
              </InputWrapper>
              {def.rdgColumn.key === "emailTo" && !isValidEmail && (
                <Tooltip title="Email is required">
                  <Warning color="error" sx={{ ml: 1 }} />
                </Tooltip>
              )}
            </Box>
          ))}
        </Box>

        {/* Date, Time, Account, and Advanced Parameters */}
        <CustomAccordion
          title="Date Parameters"
          definitions={dateDefs}
          isValid={isValidDate}
          validationMessage="Date is required!"
          showHelpIcon={true}
          helpText="Select either a Start and End Date for a range, a single Date, or a comma-separated list of Dates. If multiple fields are filled, the priority is Start/End Date > List of Dates > Single Date."
          highlightKeys={getHighlightKeys()}
        />
        <CustomAccordion title="Time Parameters" definitions={timeRefs} />
        <CustomAccordion title="Account Query Parameters" definitions={accountRefs} />
        {/* Account Output Parameters */}
        <CustomAccordion
          title="Account Output Parameters"
          definitions={[]}
          isValid={isValidAccountFields}
          validationMessage="Required Account Header Fields, ACCOUNT or ID, are missing!"
          showHelpIcon={true}
          helpText="Configure the fields to be included in the account header. Select fields manually or use auto-inclusion based on query parameters"
        >
          <Box sx={{ padding: 2 }}>
            {/* Row for Auto-Account Header Inclusion Checkbox */}
            <Box sx={{ marginBottom: 2 }}>
              <Tooltip title="When enabled, this will automatically include fields based on the query parameters you've selected. If no checkboxes are selected, the default field will be Account.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={autoAccountHeaderInclusion}
                      onChange={(e) => setAutoAccountHeaderInclusion(e.target.checked)}
                    />
                  }
                  label="Auto-Account Header Inclusion"
                />
              </Tooltip>
            </Box>

            {/* Row for AccountField Enum Checkboxes */}
            <Box sx={{ marginBottom: 2 }}>
              {Object.entries(AccountField).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={accountFieldCheckboxes.has(value)}
                      onChange={() => toggleAccountField(value)}
                    />
                  }
                  label={key}
                />
              ))}
            </Box>

            {/* Button to generate and display the Account Field Preview CSV*/}
            <Button onClick={generateAccountFieldPreview} variant="outlined">
              Generate Account Field Preview
            </Button>

            {/* Display the Generated Account Field Preview CSV */}
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="subtitle2">Selected Fields:</Typography>
              <Typography variant="body2">{accountFieldPreview}</Typography>
            </Box>
          </Box>
        </CustomAccordion>
        <CustomAccordion title="Advanced Options" definitions={advancedDefs} />
      </Box>
    </Box>
  );
};

export default ComplianceQueryTool;
