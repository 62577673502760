var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"YkHx4XcDSBSy-UNr3Al9S"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://b01ca5e2d04e4bb2b964bcc83d99d4cd@o1429278.ingest.sentry.io/6781406",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  enabled: process.env.NODE_ENV !== "development",
  beforeSend(event, hint) {
    if (event.exception) {
      if (event.exception.values?.[0].value === "feedback") {
        Sentry.showReportDialog({
          title: "Feedback",
          subtitle:
            "Please share with us any features or problems you would like.",
          labelSubmit: "Submit Feedback",
          labelComments: "Comments",
        });
      } else {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
    }
    return event;
  },

  profilesSampleRate: 1,
});
